export const frozenDishTexts = [
    {
        title: 'Asparges Suppe',
        smallPrice: '45',
        largePrice: ' 75',
    },
    {
        title: 'Boller i karry',
        smallPrice: '50',
        largePrice: ' 90',
    },
    /*{
        title: 'Finker',
        smallPrice: '45',
        largePrice: ' 75'
    },*/
    /*{
        title: 'Forloren skildpadde',
        smallPrice: '70',
        largePrice: '130',
    },*/
    {
        title: 'Gule ærter',
        smallPrice: '40',
        largePrice: ' 75',
    },
    /*{
        title: 'Gullash Suppe',
        smallPrice: '45',
        largePrice: ' 75',
    },
    {
        title: 'Kalvefrikassé',
        smallPrice: '70',
        largePrice: '130',
    },*/
    {
        title: 'Mørbradgryde',
        smallPrice: '80',
        largePrice: '150',
    },
    {
        title: 'Skipperlabskovs',
        smallPrice: '50',
        largePrice: ' 90',
    },
    {
        title: 'Stroganoff',
        smallPrice: '75',
        largePrice: '140',
    },
]

export const otherDishTexts = [
    /*{
        title: 'Biksemad',
        quantity: 'pr. bakke',
        price: '55',
    },*/
    {
        title: 'Forloren hare (bag selv)',
        quantity: 'pr. bakke',
        price: '85',
    },
    /*{
        title: 'Kalvelever med løg og flødesovs',
        quantity: 'pr. bakke',
        price: '60',
    },
    {
        title: 'Fond',
        quantity: 'pr. bakke',
        price: '35',
    },*/
    {
        title: 'Sovs',
        quantity: 'pr. bakke',
        price: '45',
    },
    {
        title: 'Høns i asparges (ca. 5 stk. tarteletter)',
        quantity: 'pr. bøtte',
        price: '85',
    },
    /*{
        title: 'Hakkebøf med løg og flødesovs',
        quantity: '2 stk.',
        price: '60',
    },
    {
        title: 'Mørbradbøf med løg og flødesovs',
        quantity: '2 stk.',
        price: '65',
    },*/
]