import React from "react";
import { Helmet } from "react-helmet";
import '../styles/styles.css';

import { frozenDishTexts, otherDishTexts } from "../components/texts/DishTexts";

//Components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Faerdigretter = () => {
    return (
        <>
            <Helmet>
                <title>Færdigretter | Klippingeslagteren</title>
            </Helmet>
            <Navbar />
            {/*<div className="mt-32 md:mt-40">
                <h3 className='text-3xl font-bold text-center pb-4'>Færdigretter</h3>
                <div className='flex flex-col md:flex-row'>
                    <div className='w-11/12 mx-auto mb-10 md:w-1/2 md:pr-10 md:pl-20'>
                        {frozenDishTexts.map(({ title, smallPrice, largePrice }) => (
                            <DishItem title={title}
                                      largePrice={largePrice}
                                      smallPrice={smallPrice}/>
                        ))}
                    </div>
                    <div className='w-11/12 mx-auto mb-10 md:w-1/2 md:pl-10 md:pr-20'>
                        {otherDishTexts.map(({ title, quantity, price}) => (
                            <DishItem title={title}
                                      smallPrice={price}
                                      quantity={quantity}/>
                        ))}
                    </div>
                </div>

                <div className='flex grid grid-rows-3 grid-cols-4 w-11/12 mx-auto mb-10'>
                    {otherDishTexts.map(({ title, quantity, price}) => (
                        <DishCard title={title}
                                  smallPrice={price}
                                  quantity={quantity}/>
                    ))}
                </div>

                <div className="pt-6 pb-10 px-2 text-center">
                    <p>
                        Der tages forbehold for udsolgte varer
                        samt trykfejl.<br /> Alle priser er afhentningspriser,
                        der bliver reguleret efter noteringen.
                        Alle priser er inkl. moms.
                    </p>
                </div>
            </div>*/}

            <div className="mt-32 md:mt-40 flex flex-col items-center pb-12">
                <div className=" pb-6 px-2 text-center text-sm">
                    <p>
                        Der tages forbehold for udsolgte varer
                        og trykfejl. <br/> Alle priser er afhentningspriser,
                        der bliver reguleret efter noteringen.
                        Alle priser er inkl. moms.
                    </p>
                </div>
                    <table className=" table-fixed m-auto w-11/12 lg:w-7/12 mb-5 text-sm sm:text-base ">
                        <thead>
                        <tr className="border-b-2">
                            <th className="w-2/4 text-left">Færdigret ( pr. bøtte )</th>
                            <th className="w-1/4">Lille</th>
                            <th className="w-1/4">Stor</th>
                        </tr>
                        </thead>
                        <tbody>
                            {frozenDishTexts.map(({title, smallPrice, largePrice}) => (
                                <tr className="border-t border-b">
                                    <td className="text-left"> {title} </td>
                                    <td className="border-l-2 ">  {smallPrice} kr. </td>
                                    <td className="border-l-2 text-center"> { parseInt(largePrice) < 99 ? "\xa0" + largePrice : largePrice  }  kr. </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <table className="table-fixed m-auto w-11/12 lg:w-7/12 text-sm sm:text-base">
                        <thead>
                        <tr className="border-b-2">
                            <th className="w-2/4 text-left"/>
                            <th className="w-1/4"/>
                            <th className="w-1/4"/>
                        </tr>
                        </thead>
                        <tbody>
                            {otherDishTexts.map(({title, quantity, price}) => (
                                <tr className="border-t border-b text-center">
                                    <td className="text-left"> {title} </td>
                                    <td className="border-l-2 "> {quantity} </td>
                                    <td className="border-l-2"> { price } kr. </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


            </div>
            <Footer />
        </>
    )
}

export default Faerdigretter